import {Dropzone} from "dropzone";
import "dropzone/dist/dropzone.css";
import dropzoneTemplate from "bundle-text:./dropzone-template.html";

let myDropzone = new Dropzone("#file-upload", {
    previewTemplate: dropzoneTemplate,
    dictDefaultMessage: "Déposer vos fichiers ici",
});

myDropzone.on("complete", function(file) {
    const blob = new Blob([file.xhr.response], {type: 'image/svg+xml'});
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = file.name.replace("pdf", "svg");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => myDropzone.removeFile(file), 1000);
});